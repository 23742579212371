.video-player {
  position: relative;
  width: fit-content;
}

.video {
  width: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
}


