.StickyBlock {
  padding: 60px 0;
  position: relative;
  &__sidebar {
    position: sticky;
    top: 100px;
    width: 300px;
    align-self: flex-start;
  }
  &__card {
    row-gap: 30px;
    display: flex;
    flex-direction: column;
    flex: none;
  }
  &__wrapper {
    display: flex;
    gap: 30px;
    &-tabs {
      display: flex;
      gap: 30px;
      &__left {
        max-width: 300px;
        width: 100%;
        .active {
          button {
            color: red !important;
            background: #115afb !important;
            color: #fff !important;
            opacity: 1 !important;
          }
        }
        &-tabulations {
          &__item {
            width: 100%;
            height: auto !important;
            min-height: auto !important;
            text-transform: none !important;
            border-radius: 6px !important;
            border: 1px solid #115afb !important;
            color: #115afb !important;
            background: #fff !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            transition: 0.3s all;
            display: flex !important;
            justify-content: space-between !important;
            padding: 8px 16px !important;
            text-align: start !important;
            letter-spacing: normal !important;
          }
        }
      }

      &__right {
        flex: 1;
      }
    }
  }
}

@media (max-width: 940px) {
  .StickyBlock {
    &__sidebar {
      position: static;
      align-self: auto;
      width: 100%;
    }
    &__wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-tabs {
        &__left {
          max-width: 100%;
          &-tabulations {
            &__item {
              width: max-content;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .StickyBlock {
    &__wrapper {
      &-tabs {
        &__right {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 545px) {
  .StickyBlock {
    padding:  30px 0;
  }
}
