.TestingLabOperationsSectionMobile {
  padding: 60px 0;

  .title {
    max-width: 850px;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-text {
      max-width: 850px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &-tabs {
      display: flex;
      gap: 30px;

      &__right {
        &-panel {
          &__image {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }

      &__left {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &__accordion {
          border-radius: 6px !important;
          background: #fff !important;
          box-shadow: none !important;
          &::before {
            display: none;
          }

          &-title {
            color: #115afb !important;

            font-family: Inter !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 20px !important; /* 142.857% */
            border-radius: 6px !important;
            border: 1px solid #115afb !important;

            * {
              margin: 0 !important;
            }
          }

          &-title.Mui-expanded {
            background-color: #115afb;
            color: #fff !important;
          }

          &-text {
            color: #484848 !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            padding: 8px 16px !important;
          }
        }
      }
    }
  }
}

.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  svg {
    color: #fff;
  }
}
