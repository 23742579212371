.UnfoldingSection {
  padding: 60px 0;

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 30px;
    max-width: 960px;
    width: 100%;
  }

  .title {
    margin-bottom: 10px;
  }

  &__wrapper {
    display: flex;
    gap: 30px;
    justify-content: start;
  }

  &__text {
    max-width: 850px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-title {
      color: #1f1f1f;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 30px;
    }

    &-text {
      color: #484848;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-accordions {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &__row {
        display: flex;
        align-items: flex-start;
        column-gap: 15px;
        &__text {
          width: calc(100% - 20px);
        }
      }

      &__accordion {
        border-radius: 10px !important;
        border: 1px solid #c9d8f4 !important;
        background: #eaf0fb !important;

        &-title {
          color: #115afb !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: 24px !important;
          min-height: auto !important;
          padding: 8px 16px !important;

          * {
            margin: 0 !important;
          }
        }

        &-text {
          color: #484848 !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 20px !important;
          padding: 8px 16px !important;
        }
      }
    }
  }

  &__image {
    max-width: 410px;
    min-width: 410px;
    width: 100%;
    border-radius: 10px;
    background: #eee;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.Mui-expanded {
  margin: 0 !important;
}

@media (max-width: 885px) {
  .UnfoldingSection {  
    &__wrapper {
      flex-direction: row-reverse !important;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media (max-width: 545px) {
  .UnfoldingSection {
    padding: 30px 0;
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    &__header {
      margin-bottom: 15px;
    }
    &__wrapper {
      gap: 15px;
    }
    &__text {
      &-title {
        font-size: 18px;
        line-height: 24px;
      }
    }  
    &__image {
      min-width: auto;
    }
  
  }
}