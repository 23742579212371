.ProductCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c9d8f4;
  background: #eaf0fb;
  padding: 20px;

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;

    span {
      color: #115afb;
    }
  }

  &__description {
    color: #484848;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  hr {
    border-top: 1px solid #c9d8f4;
  }
}

@media (max-width: 545px) {
  .ProductCard {
    max-width: none;
  }
}
