.AccordionsSection {
  padding: 60px 0;

  .title {
    max-width: 850px;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    gap: 30px;
  }

  &__text {
    max-width: 850px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-title {
      color: #1f1f1f;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }

    &-text {
      color: #484848;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-accordions {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__accordion {
        border-radius: 10px !important;
        border: 1px solid #c9d8f4 !important;
        background: #eaf0fb !important;

        &-title {
          color: #115afb !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: 24px !important;
          min-height: auto !important;
          padding: 8px 16px !important;

          * {
            margin: 0 !important;
          }
        }

        &-text {
          color: #484848 !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 20px !important;
          padding: 8px 16px !important;
        }
      }
    }
  }

  &__image {
    max-width: 410px;
    min-width: 410px;
    width: 100%;
    border-radius: 10px;
  }
  &__mobile-image {
    height: 300px !important;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    display: none;
  }
}

.Mui-expanded {
  margin: 0 !important;
}
@media (max-width: 885px) {
  .AccordionsSection {
    &__img {
      width: 350px;
      object-fit: cover;
    }
    padding: 30px 0;
    &__image {
      display: none;
      &.mobile {
        display: block;
        min-width: fit-content;
        max-width: none;
      }
    }
    &__mobile-image {
      display: block;
    }
  }
}
@media (max-width: 545px) {
  .AccordionsSection {
    .title {
      margin-bottom: 30px;
      font-size: 24px !important;
      line-height: 30px !important;
    }
    &__text {
      &-title {
        font-size: 18px;
        line-height: 24px;
      }
      &-text {
        margin-bottom: 15px;
      }
      &-accordions {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__accordion {
          border-radius: 10px !important;
          border: 1px solid #c9d8f4 !important;
          background: #eaf0fb !important;

          &-title {
            color: #115afb !important;
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 24px !important;
            min-height: auto !important;
            padding: 8px 16px !important;

            * {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}
