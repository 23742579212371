.dropdownMobile {
  position: absolute;
  top: 51px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  margin-top: 24px;
  border-top: 4px solid #0f4eda;
  background: #115afb;
  opacity: 0;
  transform: translateY(-80vh);
  height: calc(100lvh - 70px);
  pointer-events: none;
  transition:
    opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
    transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  &__nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 30px 0;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    &-accordion {
      all: unset !important;
      width: 350px !important;
      &-title {
        all: unset !important;
        div {
          all: unset !important;
        }
      }
    }
    a,
    button {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 20px;
      justify-content: center;
      margin: 0 auto;
    }
  }
  &__cards {
    padding: 30px 0 0 0 !important;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.dropdownMobile.opened {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
}

// @media (max-width: 1064px) {
//   .dropdownMobile {
//     &__nav {
//       display: none;
//     }
//   }
// }
