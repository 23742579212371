.CrelioHealthPacs {
  padding: 60px 0;
  background: #eaf0fb;
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &__cards {
      display: flex;
      gap: 30px;
      justify-content: center;
      flex-wrap: wrap;
      &__card {
        border-radius: 10px;
        border: 1px solid #c9d8f4;
        background: #fff;
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 20px;
        width: 410px;

        align-self: stretch;
        &.short {
          .title {
            font-size: 24px !important;
            line-height: 30px !important;
          }
        }

        &__imgContainer {
          width: 80px;
          height: 80px;
          border-radius: 16px;
          background: #115afb;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__text {
          width: 260px;
        }
      }
    }
  }
}
@media (max-width: 545px) {
  .CrelioHealthPacs {
    padding: 30px 0;
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    &__wrapper {
      &__cards {
        .title {
          font-size: 42px !important;
          line-height: 42px !important;
          margin-bottom: 5px;
        }
        &__card {
          max-width: 350px;
          width: 100%;
          gap: 15px;

          &__imgContainer {
            width: 40px;
            height: 40px;
            border-radius: 12px;
            padding: 6px;
            div {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
