.laboratoryInformation {
  background: #FAFAFA;
  padding: 60px 0;

  &__wrapper {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;
  }
  &__cards {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &__card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      &__image {
        width: 80px;
        height: 80px;
        background-color: #115afb;

        border-radius: 16px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    width: 850px;
  }
}

@media (max-width: 870px) {
  .laboratoryInformation {
    &__cards {
      flex-direction: column;
    }
    &__text {
      width: fit-content;
    }
  }
}
