.MedicalSoftwareSupport {
  position: relative;

  &.whited {
    background: #eaf0fb;
    &.reverse {
      background: #fff;
      &::after {
        content: '';
        width: 100%;
        height: 180px;
        background: #eaf0fb;
        position: absolute;
        top: 0;
        z-index: 1;
      }
    }
    &::after {
      content: '';
      width: 100%;
      height: 180px;
      background: #fff;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
  &::after {
    content: '';
    width: 100%;
    height: 180px;
    background: #1f1f1f;
    position: absolute;
    top: 0;
    z-index: -100;
  }
  &__block {
    border-radius: 10px;
    background: #115afb;
    display: flex;
    gap: 30px;
    padding: 60px;
    align-items: center;
    z-index: 15;
    position: relative;

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-img {
      max-width: 570px;
      width: 100%;
    }
  }
}

@media (max-width: 885px) {
  .MedicalSoftwareSupport {
    &__block {
      flex-direction: column;
      padding: 30px 20px;
    }
  }
}
