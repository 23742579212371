* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.content-wrapper {
  padding-top: 80px;
}
