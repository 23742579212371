.rowItemCard {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #c9d8f4;
  background: #fff;
  display: flex;
  gap: 20px;
  width: 410px;
  align-items: center;

  &__img {
    width: 40px;
    height: 40px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-title {
      color: #1f1f1f;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &-description {
      color: #484848;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media (max-width: 545px) {
  .rowItemCard {
    min-height: 150px;
    min-width: 150px;
    flex-direction: column;
    align-items: start;
    &__text {
      &-description {
        display: none;
      }
    }
  }
}
