.button {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  border-radius: 6px;
  background: #115afb;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    transform: scale(1.007);
  }

  &__outlined {
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #115afb;
    color: #115afb;
    background: transparent;
  }

  &__withIcon {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
