.make-your-lab-future {
  padding: 60px 0;
}

.make-your-lab-future__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
    width: 960px;
    text-align: center;
  }
}

@media (max-width: 993px) {
  .make-your-lab-future {
    &__wrapper {
      &__text {
        width: fit-content;
      }
    }
  }
}

@media (max-width: 545px) {
  .make-your-lab-future {
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    padding: 30px 0;
  }
}
