.our-statistic {
  background: #EAF0FB;
  width: 100%;

  .container {
    display: flex;
    padding: 60px 0;
    align-items: center;
    column-gap: 30px;
  }
  &__frame {
    width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      color: #115AFB;
      text-align: center;
      font-family: Inter;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 70px;
    }
    &__description {
      color: #1F1F1F;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1063px) {
  .our-statistic {
    .container {
      flex-wrap: wrap;
      row-gap: 30px;
      justify-content: center;
    }
  }
}

@media (max-width: 545px) {
  .our-statistic {
    &__frame {
      width: 150px;
      &__title {
        font-size: 42px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
}
