.solutions {
  padding: 60px 0;
  background: #eaf0fb;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
}

@media (max-width: 545px) {
  .solutions {
    padding: 30px 0;
  }
}
