.about-siroca {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;
  padding: 60px 0;

  &__textContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  &__flexxer {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;

    &__image {
      object-fit: cover;
      border-radius: 10px;
      height: 460px;
    }

    &__rightSide {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }

    &__LIMS {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      &__list {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        &__item {
          list-style: none;
          display: flex;
          column-gap: 15px;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .about-siroca {
    align-items: center;
    text-align: center;
    &__flexxer {
      flex-direction: column;
      row-gap: 30px;
    }
  }
}

@media (max-width: 545px) {
  .about-siroca {
    align-items: start;
    text-align: start;
    &__textContainer {
      width: fit-content;
    }
  }
}
