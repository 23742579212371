.container {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1289px) {
  .container {
    max-width: none;
    padding: 0 20px;
  }
}
