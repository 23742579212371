.Seamlessintegration {
  padding: 60px 0;
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-text {
      max-width: 850px;
      .title {
        margin-bottom: 15px;
      }
    }
    &-cards {
      display: flex;
      gap: 30px;
    }
  }
}

@media (max-width: 1063px) {
  .Seamlessintegration {
    &__wrapper {
      &-cards {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
@media (max-width: 545px) {
  .Seamlessintegration {
    padding:  30px 0;
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    &__wrapper {  
      &-cards {
        gap: 15px;
      }
    }
  }
}
