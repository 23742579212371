.TestingLabOperationsSection {
  padding: 60px 0;

  * {
    font-family: Inter !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-text {
      max-width: 850px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &-tabs {
      display: flex;
      gap: 30px;

      &__left {
        max-width: 300px;
        width: 100%;

        &-tabulations {
          &__item {
            width: 100%;
            height: auto !important;
            min-height: auto !important;
            text-transform: none !important;
            border-radius: 6px !important;
            border: 1px solid #115afb !important;
            color: #115afb !important;
            background: #fff !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            transition: 0.3s all;
            display: flex !important;
            justify-content: space-between !important;
            padding: 8px 16px !important;
            text-align: start !important;
            letter-spacing: normal !important;

            &.Mui-selected {
              color: red !important;
              background: #115afb !important;
              color: #fff !important;
            }
          }
        }
      }

      &__right {
        flex: 1;

        &-panel {
          display: flex;
          gap: 30px;

          &__content {
            display: flex;
            flex-direction: column;
            gap: 30px;
          }

          &__text {
            max-width: 520px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
          }

          &__image {
            flex: 1;
            height: 402px;
            border-radius: 10px;
            width: 100%;
            max-width: 410px;
            min-width: 410px;
          }
        }
      }
    }
  }
}

.MuiTabs-indicator {
  display: none !important;
}

.MuiTabs-flexContainer {
  display: flex;
  // flex-direction: column;
  gap: 15px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 1024px) {
  .TestingLabOperationsSection {
    &__wrapper {
      &-tabs {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .TestingLabOperationsSection {
    &__wrapper {
      &-tabs {
        flex-direction: column;
        align-items: center;
        &__right {
          &-panel {
            flex-direction: column;
            align-items: center;
            &__image {
              flex: auto;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 545px) {
  .TestingLabOperationsSection {
    padding: 30px 0;
    &__wrapper {
      &-tabs {
        flex-direction: column;
        align-items: center;
        &__left {
          max-width: 100% !important;
          &-tabulations {
            &__item {
              max-width: 100% !important;
            }
          }
        }
        &__right {
          &-panel {
            flex-direction: column;
            align-items: center;
            &__image {
              min-width: auto;
              height: 200px;
            }
          }
        }
      }
    }
  }
}
