.ContactPopupDialog {
  .MuiPaper-root {
    padding: 30px;
    border-radius: 10px;
    border: none;
  }

  .MuiInputBase-root {
    border-radius: 6px;
    padding: 0 !important;
  }
  .MuiInputBase-input {
    padding: 10px 15px !important;
    outline: none;
    &::placeholder {
      color: #808080;
      opacity: 1;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .MuiFormLabel-root {
    line-height: normal;
    top: -5px;
    color: #808080;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #e6e8eb;
  }

  .MuiAutocomplete-endAdornment {
    right: 15px;
    top: 10px;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #e6e8eb;
      height: 43px;
    }
    .flag-dropdown {
      background: #fff;
      border: 1px solid #e6e8eb;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    position: relative;
    &__close {
      position: absolute !important;
      right: 0;
      top: 0;
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      &__btn {
        margin-top: 30px;
      }
    }
  }
}
