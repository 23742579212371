.CustomerFeedbackPanel {
  background: #eaf0fb;
  padding: 60px 0;
  position: relative;

  &__background {
    background: #43D8C3;
    height: 346px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 330px;
      height: 330px;
      background: #30CFB9;
      border-radius: 50%;
      bottom: -100px;
      right: -100px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 3;
    &-header {
      display: flex;
      gap: 30px;

      h2 {
        max-width: 630px;
        width: 100%;
        flex: 1;
      }

      p {
        flex: 1;
      }
    }

    &-logos {
      padding: 30px 0;
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-reviews {
      padding-top: 30px;
      display: flex;
      gap: 30px;
      &__left {
        max-width: 630px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-buttons {
          margin-top: 15px;
        }
      }

      &__right {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

@media (max-width: 595px) {
  .CustomerFeedbackPanel {
    &__wrapper {
      &-reviews {
        flex-direction: column;
      }
      &-header {
        flex-direction: column;
      }
      &-logos {
        img {
          width: 160px;
        }
      }
    }
  }
}
