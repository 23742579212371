.dropdown {
  // dropdown styles
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  // text styles
  font-family: 'Inter', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  &__icon {
    &__rotated {
      transform: rotate(180deg);
      transition: 0.2s;
    }
    &__default {
      transition: 0.2s;
    }
  }
}
