.header {
  background: #115afb;
  padding: 20px 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 6;
  }
  &__nav {
    display: flex;
    align-items: center;
    column-gap: 42px;
    z-index: 3;

    &__link {
      color: #fff;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration: none;
    }
    &__contact {
      color: #fff;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-decoration: none;

      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
  &__burger {
    &-btn {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    &__nav {
      display: none;
    }
    &__burger {
      &-btn {
        display: block;
      }
    }
  }
}
