.VideoPlayer {
  padding: 60px 0;
  &__video {
    position: relative;
    max-width: 410px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    &__cards {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  &__flexxer {
    display: flex;
    gap: 30px;
  }

  &__row {
    display: flex;
    gap: 15px;
    &__text {
      color: #484848 !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      width: calc(100% - 20px);
      .title {
        margin-bottom: 10px;
      }
    }
  }
}
@media (max-width: 885px) {
  .VideoPlayer {
    &__flexxer {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 545px) {
  .VideoPlayer {
    padding: 30px 0;
  }
}
