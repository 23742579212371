.header__card {
  max-width: 300px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  &-top {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    padding: 20px;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .title {
        color: #1f1f1f;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }

      .description {
        color: #484848;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  &-bottom {
    padding: 20px;
    display: flex;
    gap: 20px;
    border-top: 1px solid #eee;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        width: 100%;
        max-width: 103px;
      }
    }

    .button__outlined {
      max-width: 103px;
      width: 100%;
      flex: 1;
    }
  }
}
@media (max-width: 1200px) {
  .header__card {
    max-width: 350px;
    &-top {
      &__text {
        .title {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    &-bottom {
      a {
        button {
          font-size: 14px !important;
          font-weight: 400;
          line-height: 20px !important; 
        }
        &:last-child {
          button {
            color: #115AFB !important;
          }
        }
      }
    }
  }
}

