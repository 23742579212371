.ServiceHighlightSection {
  padding: 60px 0;
  background: #eee;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 850px;

      &__cards {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 545px) {
  .ServiceHighlightSection {
    padding: 30px 0;
  
  }
}