.MapSection {
  padding: 60px 0 91px;
  &__wrapper {
    display: flex;
    max-width: 850px;
    width: 100%;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    border-radius: 10px;
    background: #115afb;
    margin: 0 auto;

    h2 {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }

    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
