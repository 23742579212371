.ReviewsCard {
  display: flex;
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #c9d8f4;
  background: #fff;

  h2 {
    margin-top: 15px;
  }

  p {
    margin-top: 5px;
  }
}
