.MultipleModalities {
  padding: 60px 0;
  background: #FAFAFA;

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    &__text {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 30px;
      justify-content: center;
    }
  }
  &__card {
    width: 300px;
    border-radius: 10px;
    border: 1px solid #c9d8f4;

    background: #fff;
    padding: 20px;

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__imgContainer {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      background: #115afb;
    }
  }
}

@media (max-width: 545px) {
  .MultipleModalities {
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    padding: 30px 0;
    &__wrapper {
      &__cards {
        justify-content: center;
      }
    }
    &__card {
      row-gap: 15px;
    }
  }
  
}