.dropdownMenu {
  position: absolute;
  top: 56px;
  width: 100%;
  padding-bottom: 30px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  margin-top: 24px;
  border-top: 4px solid #0f4eda;
  padding-top: 30px;
  background: #115afb;
  opacity: 0;
  transform: translateY(-50px);
  transition:
    opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
    transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.dropdownMenu.opened {
  opacity: 1;
  transform: translateY(0);
}



@media (max-width: 1064px) {
  .dropdownMenu {
    &__nav {
      display: none;
    }
  }
}
