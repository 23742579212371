.HealthcareSoftwareCertifications {
  padding: 60px 0;

  &__wrapper {
    display: flex;
    gap: 30px;

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 630px;
      width: 100%;
    }

    &-images {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      gap: 30px;
      &__item {
        width: 300px;
        height: 160px;
        border-radius: 10px;
        border: 1px solid #c9d8f4;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
      }
    }
  }
}

@media (max-width: 885px) {
  .HealthcareSoftwareCertifications {
    padding: 30px 0;
    &__wrapper {
      flex-direction: column;
      &-images {
        align-items: center;
        justify-content: center;
        &__item {
          width: 100%;
        }
      }
    }
  }
}
