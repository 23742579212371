.rowItem {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__title {
    color: #1f1f1f;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  &__items {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }
}

@media (max-width: 1063px) {
  .rowItem {
    &__items {
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
    }
    &__title {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media (max-width: 663px) {
  .rowItem {
    &__items {
      flex-wrap: nowrap;
      justify-content: start;
      overflow-x: auto;
      max-width: 95vw;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
}
