.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  align-self: stretch;
  width: 100%;

  padding: 20px;
  border-radius: 10px;
  border: 1px solid #c9d8f4;
  background: #eaf0fb;

  &__icon {
    width: 30px;
  }
  &__title {
    color: #1f1f1f;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
  }
  &__description {
    color: #484848;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
