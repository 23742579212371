.StickyBlockCard {
  max-width: 630px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 20px;
  &__images {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .per-one {
      width: 300px;
      height: 131px;
    }
    .per-two {
      width: 300px;
      height: 131px;
    }
    .per-three {
      width: 300px;
      height: 131px;
    }
  }
}
