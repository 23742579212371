.footer {
  background: #EAF0FB;
  padding: 60px 0;
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    &__cols {
      display: flex;
      column-gap: 30px;
      &__col {
        display: flex;
        flex-direction: column;
        width: 190px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        &__title {
          height: 60px;
        }
        &__link {
          color: #484848;

          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &__socials {
        display: flex;
        column-gap: 30px;
      }
    }
  }
}

@media (max-width: 950px) {
  .footer {
    &__wrapper {
      &__cols {
        flex-direction: column;
        row-gap: 30px;
      }
    }
  }
}
@media (max-width: 656px) {
  .footer {
    &__wrapper {
      &__bottom {
        justify-content: start;
        align-items: start;
        flex-direction: column;
        row-gap: 15px;
      }
    }
  }
}

@media (max-width: 415px) {
  .footer {
    &__wrapper {
      &__bottom {
        &__socials {
          justify-content: space-between;
          width: 100%;
          column-gap: normal;
        }
      }
    }
  }
}
