.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
  &__background {
    background: url('../../assets/pageTitleBg.png');
  }

  &__leftSide {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 45%;
    &__title {
      color: #1f1f1f;
      font-family: Inter;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px; /* 120.69% */
    }
    &__description {
      color: #484848;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 125% */
    }
    &__btnContainer {
      margin-top: 20px;
      display: flex;
      column-gap: 30px;
      align-items: center;
    }
  }
}

@media (max-width: 1063px) {
  .page-title {
    &__rightSide {
      &__image {
        width: 450px;
      }
    }
  }
}
@media (max-width: 885px) {
  .page-title {
    &__leftSide {
      align-items: center;
      text-align: center;
      width: fit-content;
    }
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
  }
}

@media (max-width: 650px) {
  .page-title {
    &__leftSide {
      &__btnContainer {
        flex-direction: column;
        row-gap: 15px;
        width: 100%;
        a {
          width: 100%;
          button {
            width: 100%;
          }
        }
        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 545px) {
  .page-title {
    &__leftSide {
      &__title {
        font-size: 32px !important;
        line-height: 40px !important;
      }
      &__description {
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }
    &__rightSide {
      &__image {
        width: 350px;
      }
    }
  }
}
